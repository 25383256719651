<template>
  <div class="display-content goodness">
    <h1>技术和产品优势</h1>
    <hr class="content-line">
    <div class="goodness-box">
      <div class="goodness-pre">
        <div class="goodness-img">
          <span class="goodness-icon1"></span>
        </div>
        <p class="goodness-topic">极简使用</p>
        <div class="goodness-info">
          <p>学习成本低，辅以完备的指导说明，践行简单易用、即安即用的理念。</p>
        </div>
      </div>
      <div class="goodness-pre">
        <div class="goodness-img">
          <span class="goodness-icon2"></span>
        </div>
        <p class="goodness-topic">通话解决方案</p>
        <div class="goodness-info">
          <p>多种通话解决方案，帮助企业电销团队快速开展业务，满足不同团队的使用场景需求，平台稳定</p>
        </div>
      </div>
      <div class="goodness-pre">
        <div class="goodness-img">
          <span class="goodness-icon3"></span>
        </div>
        <p class="goodness-topic">销售管理</p>
        <div class="goodness-info">
          <p>通过云电销公海管理、商机券、数据报表、监控可以及时掌握销售工作状态，帮助管理者更有效管理销售</p>
        </div>
      </div>
      <div class="goodness-pre">
        <div class="goodness-img">
          <span class="goodness-icon4"></span>
        </div>
        <p class="goodness-topic">安全无忧</p>
        <div class="goodness-info">
          <p>不涉及敏感数据，使用非爬虫技术，有效规避风险又能最大化方便使用。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auGoodness",
  methods:{
    toDownLoad(){
      this.$router.push('/download');
    }
  }
}
</script>

<style scoped lang="scss">
.goodness {
  padding-top: 60px;
  margin-bottom: 160px;
}

.display-content {
  width: 1170px;
  margin: 0 auto;
  padding-top: 70px;
  color: #999999;
  font-size: 14px;
}
.display-content h1 {
  font-size: 22px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}
.display-content .content-line {
  width: 70px;
  height: 2px;
  background: #298dff;
  border: none;
  margin: 0 auto;
}
.goodness .goodness-box {
  overflow: hidden;
  margin-top: 90px;
}
.goodness .goodness-box .goodness-pre {
  width: 25%;
  float: left;
}
.goodness .goodness-box .goodness-pre .goodness-img {
  width: 96px;
  height: 96px;
  margin: 0 auto 42px;
}
.goodness-icon1 {
  display: inline-block;
  width: 96px;
  height: 96px;
  background: url('../assets/goodness-icon1.png') no-repeat center;
}

.goodness-icon2 {
  display: inline-block;
  width: 96px;
  height: 96px;
  background: url('../assets/goodness-icon2.png') no-repeat center;
}

.goodness-icon3 {
  display: inline-block;
  width: 96px;
  height: 96px;
  background: url('../assets/goodness-icon3.png') no-repeat center;
}

.goodness-icon4 {
  display: inline-block;
  width: 96px;
  height: 96px;
  background: url('../assets/goodness-icon4.png') no-repeat center;
}
.goodness-topic {
  font-size: 16px;
  line-height: 16px;
  color: #3b516a;
  font-weight: bold;
  text-align: center;
}
.goodness-info {
  text-align: left;
  margin-top: 18px;
  padding: 0 46px;
}
.goodness-info p {
  font-size: 14px;
  color: #95989d;
  line-height: 26px;
}

</style>
