<template>
    <div class="pageHome">
        <div class="c header">
            <div class="logoH">
                <img src="../assets/logo.png" class="logo" />
                <h2>便捷小工具网站</h2>
            </div>
            <div class="nav">
                <el-menu :default-active="activeIndex" class="auNav" mode="horizontal" @select="handleSelect">
                    <el-menu-item :index="1">首页</el-menu-item>
                    <el-menu-item :index="2">功能</el-menu-item>
                    <el-menu-item :index="3">下载</el-menu-item>
                    <el-menu-item :index="7">软件注册</el-menu-item>
                    <el-menu-item :index="4">电脑安装教程</el-menu-item>
                    <el-menu-item :index="8">手机安装教程</el-menu-item>
                    <el-menu-item :index="6">图文教程</el-menu-item>
                    <el-menu-item :index="5">联系客服</el-menu-item>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "auHeader",
        data() {
            return {
                activeIndex: 1
            }
        },
        methods: {
            handleSelect(e) {
                if (e === 1) {
                    this.$router.push('/')
                } else if (e === 2) {
                    this.$router.push('/GongNeng')
                } else if (e === 3) {
                    this.$router.push('/download')
                } else if (e === 4) {
                    window.open(this.config.shipingLink)
                } else if (e === 5) {
                    window.open("tencent://message/?uin=" + this.config.qq + "&Site=sc.chinaz.com&Menu=yes")
                } else if (e === 6) {
                    this.$router.push('/help')
                } else if (e === 7) {
                    window.open('http://easycall.easycall.top/Account/Register?rid=3')
                } else if (e === 8) {
                    window.open(this.config.mshipingLink)
                }
                console.log(e);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../public.scss";

    .pageHome {
        height: $header-height;
        width: 100%;
        text-align: center;
        background: var(--au-color);
        top: 0;
        left: 0;
        position: fixed;
        display: flex;
        color: #ffffff;
        z-index: 9;
    }

    .logo {
        width: 60px;
        height: 60px;
    }

    .logoH {
        display: flex;
        align-items: center;
    }

    .header {
        display: flex;
        align-items: center;
        height: $header-height;
        justify-content: space-between;
    }

    .auNav {
        background: none;
        color: #ffffff;
        border-bottom: none !important;
    }

    /deep/ {
        .el-menu--horizontal > .el-menu-item

    {
        color: #ffffff;
        transition: all .3s;
        font-weight: normal;
        &:hover,&:focus

    {
        color: #ffffff !important;
        font-weight: bold;
        background: none !important;
    }

    }

    .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid white;
        color: #fff;
        font-weight: bold;
    }
    }
</style>
