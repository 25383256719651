<template>
  <div class="display-content goodness region">
    <h1>功能介绍</h1>
    <hr class="content-line">
    <div class="goodness-box">
      <div class="c">
        <div class="part part1">
          <div class="name">
            <span style="color:#FF6542;font-size:14px;margin-bottom: 10px;display: block;">呼叫任务——确保销售工作量</span>
            <strong>支持数据导入——快速制定呼叫任务</strong>
            <p>管理员制定座席每日、每周通话数量、通话时长，云电销系统可设定时间内对座席人员及时提醒，制定有目标的呼叫计划，有利于提高座席人员的工作效率，呼叫任务完成度，可以帮助管理人员日常考核销售人员工作情况</p>
            <a href="javascript:void(0);" @click="toDownLoad" class="skip-wx-btn">
              立即使用
              <img src="../assets/start-btn.png" alt="">
            </a>
          </div>
          <div class="part1-r">
            <img src="../assets/call_task.png" alt="">
          </div>
        </div>
        <div class="block1">
          <strong>联系人号码加密——避免信息泄露 </strong>
          <a href="javascript:void(0);" @click="toDownLoad" class="skip-wx-btn">
            立即使用
            <img src="../assets/start-btn.png" alt="">
          </a>
          <img src="../assets/num_entry.png" alt="" class="bg">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auGoodness",
  methods:{
    toDownLoad(){
      this.$router.push('/download');
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/p1";
.goodness {
  padding-top: 60px;
  margin-bottom: 160px;
}

.display-content {
  width: 1170px;
  margin: 0 auto;
  padding-top: 70px;
  color: #999999;
  font-size: 14px;
}
.display-content h1 {
  font-size: 22px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}
.display-content .content-line {
  width: 70px;
  height: 2px;
  background: #298dff;
  border: none;
  margin: 0 auto;
}
.goodness .goodness-box {
  overflow: hidden;
  margin-top: 90px;
}
.dx_intruct{
  padding: 120px 0;
  background: rgba(248,250,252,1);
  h3{
    font-size: 34px;
    font-weight: 400;
    color: rgba(39,48,69,1);
    line-height: 48px;
    text-align: center;
  }
  p.legend {
    font-size: 18px;
    color: rgba(89,101,128,1);
    line-height: 26px;
    margin-top: 20px;
    text-align: center;
  }
  ul{
    width: 1100px;
    margin: 0 auto;
    margin-top: 80px;
    list-style: none;
    display: flex;
  }
  ul li {
    width: 206px;
    padding: 44px 0 38px;
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06);
    border-radius: 4px;
    margin-left: 92px;
    margin-top: 30px;
    img{
      vertical-align: middle;
    }
    p{
      font-size: 20px;
      color: rgba(39,48,69,1);
      line-height: 28px;
      margin-top: 30px;
    }
  }
  ul li.top {
    margin-top: 0;
  }
  ul li.one {
    margin-left: 0;
  }

}
</style>
