<template>
  <div id="app">
    <au-header></au-header>
    <div class="pageHome">
      <router-view/>
    </div>
    <au-footer></au-footer>
  </div>
</template>

<script>
import auHeader from './components/auHeader'
import auFooter from "@/components/auFooter";

export default {
  components:{
    auHeader,auFooter
  }
}
</script>

<style lang="scss">
@import "./public.scss";
html,body{
  padding: 0;
  margin: 0;
}
*{
  font-family: 'PingFang SC', tahoma, arial, 'helvetica neue', 'hiragino sans gb', 'microsoft yahei ui', 'microsoft yahei', simsun, sans-serif !important;
  -webkit-font-smoothing: antialiased;
}
</style>
<style lang="scss" scoped>
@import "./public.scss";
#app{
  padding-top: $header-height;
}
</style>
