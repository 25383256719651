<template>
  <div class="display-content goodness region">

  </div>
</template>

<script>
export default {
  name: "auGoodness",
  methods:{
    toDownLoad(){
      this.$router.push('/download');
    }
  }
}
</script>

<style scoped lang="scss">

</style>
