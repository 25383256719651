<template>
  <div class="dx_intruct has_bg">
    <h3>便捷小工具网站</h3>
    <p class="legend">功能强大——全新升级</p>
    <ul class="clearfix">
      <li class="one top">
        <img src="../assets/t1.png" alt="" width="36px" height="35px">
        <p>一键呼叫</p>
      </li>
      <li class="top">
        <img src="../assets/t2.png" alt="" width="36px" height="35px">
        <p>通话录音</p>
      </li>
      <li class="top">
        <img src="../assets/t3.png" alt="" width="36px" height="35px">
        <p>呼叫记录</p>
      </li>
      <li class="top">
        <img src="../assets/t4.png" alt="" width="36px" height="35px">
        <p>时时提醒</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "auGoodness",
  methods:{
    toDownLoad(){
      this.$router.push('/download');
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/p1";
.goodness {
  padding-top: 60px;
  margin-bottom: 160px;
}

.display-content {
  width: 1170px;
  margin: 0 auto;
  padding-top: 70px;
  color: #999999;
  font-size: 14px;
}
.display-content h1 {
  font-size: 22px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}
.display-content .content-line {
  width: 70px;
  height: 2px;
  background: #298dff;
  border: none;
  margin: 0 auto;
}
.goodness .goodness-box {
  overflow: hidden;
  margin-top: 90px;
}
.dx_intruct{
  padding: 120px 0;
  background: rgba(248,250,252,1);
  h3{
    font-size: 34px;
    font-weight: 400;
    color: rgba(39,48,69,1);
    line-height: 48px;
    text-align: center;
  }
  p.legend {
    font-size: 18px;
    color: rgba(89,101,128,1);
    line-height: 26px;
    margin-top: 20px;
    text-align: center;
  }
  ul{
    width: 1100px;
    margin: 0 auto;
    margin-top: 80px;
    list-style: none;
    display: flex;
  }
  ul li {
    width: 206px;
    padding: 44px 0 38px;
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06);
    border-radius: 4px;
    margin-left: 92px;
    margin-top: 30px;
    img{
      vertical-align: middle;
    }
    p{
      font-size: 20px;
      color: rgba(39,48,69,1);
      line-height: 28px;
      margin-top: 30px;
    }
  }
  ul li.top {
    margin-top: 0;
  }
  ul li.one {
    margin-left: 0;
  }

}
</style>
