<template>
    <div>
      更少的人力成本，更高的服务效率
    </div>
</template>

<script>
    export default {
        name: "auFooter"
    }
</script>

<style scoped>
    div {
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: #2b2b2b;
        line-height: 30px;
        margin-top: 40px;
        margin-bottom: 5px;
    }
</style>
