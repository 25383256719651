<template>
  <div class="page">
    <div class="clerk">
      <div class="arrow-box">
        <span class="left-arrow arrow"></span>
        <span class="title">便捷小工具网站 - 电话拨号软件</span>
        <span class="ft30">永久免费，电脑一键拨打电话，提高销售工作效率 </span>
        <span class="right-arrow arrow"></span>
      </div>
      <template v-if="!isDownload">
        <a href="javascript:void(0);" @click="toDownLoad" class="update color">立即下载</a>
        <a href="javascript:void(0);" @click="toDownLoad2" class="btn">使用帮助</a>
      </template>
      <div v-else href="javascript:void(0);" class="ts"></div>
      <!--<a v-if="isDownload" href="javascript:void(0);" style="width: 240px" @click="yjaz" class="update color">一键安装</a>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "auClerk",
  methods: {
    toDownLoad() {
      this.$router.push('/download');
    },
    toDownLoad2() {
      this.$router.push('/help');
    },
    yjaz() {
      this.$emit('download', this.config.DownAll)
    }
  },
  props: {
    isDownload: false
  },
}
</script>

<style scoped lang="scss">
.clerk {
  width: 100%;
}

.page {
  width: 100%;
  padding-top: 80px;
  background-color: var(--au-color);
  color: #ffffff;
  text-align: center;
}

.arrow-box {
  position: relative;
  width: 1170px;
  margin: 0 auto;
}


.arrow-box .left-arrow {
  width: 157px;
  height: 5px;
  bottom: 90px;
  background: url("../assets/arrow_left.png") no-repeat center;
  left: 15px;
}

.arrow-box .right-arrow {
  width: 156px;
  height: 4px;
  bottom: 90px;
  background: url("../assets/arrow_right.png") no-repeat center;
  right: 15px;
}

.update {
  display: inline-block;
  width: 130px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #ffffff;
  background: #fff;
  border-radius: 27px;
  margin-top: 40px;
  position: relative;
  cursor: pointer;
  margin-bottom: 42px;
  margin-right: 15px;
}

.update a {
  color: var(--au-color);
}

.header .banner-content {
  width: 100%;
  padding-top: 100px;
  background-color: var(--au-color);
  color: #ffffff;
  text-align: center;
}

.clerk .title {
  width: 100%;
  height: 90px;
  font-weight: bold;
  font-size: 60px;
  display: inline-block;
}

.clerk .arrow-box {
  position: relative;
  width: 1170px;
  margin: 0 auto;
}

.clerk .arrow-box .arrow {
  display: inline-block;
  position: absolute;
  top: 40px;
}


.clerk .btn {
  display: inline-flex;
  width: 130px;
  height: 42px;
  line-height: 42px;
  border: 2px solid #ffffff;
  background: transparent;
  color: #ffffff;
  border-radius: 21px;
  margin-top: 27px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.update {
  display: inline-block;
  width: 130px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #ffffff;
  background: #fff;
  border-radius: 27px;
  margin-top: 40px;
  position: relative;
  cursor: pointer;
  margin-bottom: 42px;
  margin-right: 15px;
  color: var(--au-color);
}
.ts{
  height: 122px;
}
</style>
