<template>
  <div class="home">
    <au-clerk></au-clerk>
    <au-goodness></au-goodness>
    <au-goodness2></au-goodness2>
    <au-goodness4></au-goodness4>
    <au-goodness5></au-goodness5>
  </div>
</template>

<script>
import auClerk from "@/components/auClerk";
import auGoodness from "@/components/auGoodness";
import auGoodness2 from "@/components/auGoodness2";
import auGoodness4 from "@/components/auGoodness4";
import auGoodness5 from "@/components/auGoodness5";
import auFooter from "@/components/auFooter";

export default {
  name: 'Home',
  components: {
    auClerk,auGoodness,auGoodness2,auFooter,auGoodness4,auGoodness5
  }
}
</script>
