import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'

Vue.config.productionTip = false;

Vue.prototype.config = {
    qq: "837001634",
    email: "837001634@qq.com",
    //360极速浏览器下载地址
    appLink: 'http://www.easycall.top/Down/easycall.apk',
    exeLink: 'http://www.easycall.top/Down/easycall.zip',
    shipingLink: 'https://v.qq.com/x/page/p3245vi05kw.html',
    mshipingLink: 'https://v.qq.com/x/page/g3245drq1uh.html',
};


new Vue({
    router,
    render: h => h(App)
}).$mount('#app');

